export default (params) => ({
    mapElement: null,
    result: [],

    layout: params.layout,
    projectResultsMarkers: params.projectResultsMarkers,

    mapCenter: Alpine.$persist(null).as('projects-search-map-center'),
    mapZoom: Alpine.$persist(null).as('projects-search-map-zoom'),
    mapOpenMarker: Alpine.$persist(null).as('projects-search-map-open-marker'),
    scrollPosition: Alpine.$persist(null).as('projects-search-scroll-position'),
    firstRenderMarker: true,

    loading: true,

    marker: [],

    init() {
        console.log('open marker:', this.mapOpenMarker)

        this.events().forEach(event => {
            window.addEventListener(event.eventName, event.callback)
        })

        if (this.layout.includes('map')) {
            this.$nextTick(() => {
                this.initMap()
            })
        }

        this.$watch('projectResultsMarkers', value => {
            console.log('> WATCHER projectResultsMarkers')
            this.renderMarker()
        })
    },

    layoutUpdated(e) {
        if (e.detail.layout.includes('map')) {
            this.$nextTick(() => this.initMap())
            this.$nextTick(() => this.renderMarker())
        } else {
            this.destroyMap()
        }
    },

    destroy() {
        this.events().forEach(event => {
            window.removeEventListener(event.eventName, event.callback)
        })
    },

    events() {
        return [{
            eventName: 'map-open-project',
            callback: event => {
                this.openMarker(event.detail.id, true)
            }
        }, {
            eventName: 'scroll-result-top',
            callback: event => {
                this.$nextTick(() => this.scrollResultToTop(false))
            }
        }]
    },

    createMarker(project) {
        const icon = L.divIcon({
            className: 'leaflet-project-icon',
            html: `<div>${project.offers_label}</div>`,
            iconAnchor: [40, 0],
            iconSize: [80, null]
        });

        const marker = L.marker([
            project.latitude,
            project.longitude,
        ], {
            id: project.id,
            icon
        }).addTo(this.mapElement)

        marker.bindPopup(() => `
            <img src="${project.image_map}" alt="${project.headline}" class="aspect-[4/3]" />
            <div class="p-3 text-xxs leading-normal">
                <div class="uppercase font-bold grow line-clamp-1 break-all">${project.headline}</div>
                <div class="line-clamp-1 break-all">${project.address_lineOne}</div>
                <div class="line-clamp-1 break-all">${project.address_lineTwo}</div>
                <div class="mt-2">
                    <a href="${project.url}" target="_blank" class="!inline-block leading-none whitespace-nowrap bg-primary !text-white rounded hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 text-xxs px-2 py-1.5">${project.offers_label}</a>
                </div>
            </div>
        `, {
            maxWidth: 140,
            minWidth: 140,
            className: 'leaflet-project-popup',
        })

        marker.on('popupopen', () => {
            L.DomUtil.addClass(marker._icon, 'active');

            this.mapOpenMarker = project.id

            this.$dispatch('card-project-set-active', project.id)

            marker.setZIndexOffset(100)
        })

        marker.on('popupclose', () => {

            if (marker._icon) {
                L.DomUtil.removeClass(marker._icon, 'active');
            }

            this.mapOpenMarker = null

            this.$dispatch('card-project-set-active', null)

            marker.setZIndexOffset(0)
        })

        marker.on('click', () => {
            this.scrollToProject(project.id)
        });

        this.marker.push(marker)

        this.$nextTick(() => {
            if (this.mapOpenMarker === project.id) {
                this.openMarker(project.id)
            }
        })
    },

    fitMarkerToScreen() {
        console.log('> fitMarkerToScreen', this.marker.length)

        if (this.mapElement === null) {
            console.warn('> initMap() = Map was not inited')
            return;
        }

        if (this.marker === null || this.marker.length <= 0) {
            return
        }

        this.mapElement.fitBounds(new L.featureGroup(this.marker).getBounds())
    },

    renderMarker() {
        console.log('> renderMarker', this.projectResultsMarkers, this.scrollPosition)

        this.deleteAllMarker()

        if (this.projectResultsMarkers === null || this.projectResultsMarkers.length <= 0) {
            return
        }

        this.$nextTick(() => {
            this.projectResultsMarkers.forEach(marker => this.createMarker(marker))
        })

        if (this.firstRenderMarker && this.scrollPosition) {
            this.$nextTick(() => {
                this.$refs.result.scroll({
                    top: this.scrollPosition,
                    left: 0,
                    behavior: 'smooth',
                })

                this.scrollPosition = null
            })
        }

        if (this.firstRenderMarker && (this.mapCenter === null || this.mapZoom === null)) {
            this.$nextTick(() => this.fitMarkerToScreen())
        }

        this.firstRenderMarker = false
    },

    deleteAllMarker() {
        console.log('> deleteAllMarker()', this.marker)

        if (this.mapElement === null || this.marker === null || this.marker.length <= 0) {
            return
        }

        this.marker.forEach(marker => this.mapElement.removeLayer(marker))

        this.marker = []
    },

    openMarker(id = '', scrollToMarker = false) {
        this.marker.forEach(marker => {
            if (marker.options.id === id) {
                marker.openPopup()

                if (scrollToMarker) {
                    this.mapElement.setView(marker.getLatLng())
                }
                return
            }
        })
    },

    scrollToProject(id = '') {

        if (this.layout !== 'map_list') {
            return
        }

        const resultContainerHeight = this.$refs.result.getBoundingClientRect().height;
        const resultElement = this.$refs.result.querySelector(`#project-${id}`);

        let position = resultElement.offsetTop - (resultContainerHeight / 2) + (resultElement.offsetHeight / 2);

        if (position <= 0) {
            position = resultElement.offsetTop
        }

        this.$refs.result.scroll({
            top: position,
            left: 0,
            behavior: 'smooth',
        })
    },

    initMap() {
        if (this.mapElement) {
            this.mapElement.invalidateSize()
            console.warn('> initMap() = Map was already inited')
            return;
        }

        console.log('> initMap()', this.mapElement)

        const mapCenter = this.mapCenter || [52.51493400936024, 13.404532180554508]
        const mapZoom = this.mapZoom || 12

        if (typeof this.$refs.map == 'undefined') {
            console.error('map container not found')
            return;
        }

        // Create the map
        this.mapElement = L.map(this.$refs.map, {
            attributionControl: false
        })
            .setView(mapCenter, mapZoom)
            .on('zoomend', () => this.saveMapSettings())
            .on('moveend', () => this.saveMapSettings())

        // Set up the OSM layer
        L.tileLayer(
            import.meta.env.VITE_MAP_URL + '/styles/invest-berlin/{z}/{x}/{y}.png', {
            attribution: '<a href="https://www.maptiler.com/copyright/" rel="noopener" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" rel="noopener" target="_blank">&copy; OpenStreetMap contributors</a>',
            maxZoom: 18,
            crossOrigin: 'anonymous'
        }).addTo(this.mapElement);

        L.control.attribution({
            position: "bottomright",
            prefix: "",
        }).addTo(this.mapElement);
    },

    scrollResultToTop(smooth = true) {
        if (this.layout === 'map') {
            return
        }

        let element

        if (this.layout === 'map_list') {
            element = this.$refs.result
        } else {
            element = window
        }


        console.log('scrollResultToTop', element)

        element.scrollTo({
            top: 0,
            left: 0,
            behavior: smooth ? 'smooth' : 'instant',
        })
    },

    saveMapSettings() {
        this.mapZoom = this.mapElement.getZoom()
        this.mapCenter = this.mapElement.getCenter()
    },

    destroyMap() {
        if (!this.mapElement) {
            console.warn('> destroyMap() = Map was already destroyed')
            return
        }

        console.log('> destroyMap()')

        this.mapElement.remove()
        this.mapElement = null
        this.firstRenderMarker = true
    }
})